<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: [ 'chartData', 'options'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    update() {
      this.$data._chart.update()
    }
  },
};
</script>

<style>
</style>