<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <b-card class="mt-3">
        <b-row>
            <b-col cols="4">
                <b-card header="Options">
                    <b-col>
                        <b-row>
                            <b-button-group size="sm">
                                <b-button
                                    :pressed.sync="statsAllSelected"
                                    id="stats_all"
                                    @click.prevent="showAllStatistics()"
                                    >All</b-button
                                >
                                <b-button
                                    :pressed.sync="statsLastMonthSelected"
                                    id="stats_last_month"
                                    @click.prevent="showLastMonthStatistics()"
                                    >Last Month</b-button
                                >
                                <b-button
                                    :pressed.sync="statsThisMonthSelected"
                                    id="stats_this_month"
                                    @click.prevent="showThisMonthStatistics()"
                                    >This Month</b-button
                                >
                                <b-button
                                    :pressed.sync="statsThisWeekSelected"
                                    id="stats_last_week"
                                    @click.prevent="showWeeklyStatistics()"
                                    >This Week</b-button
                                >
                                <b-button
                                    :pressed.sync="statsDateRangeSelected"
                                    id="stats_range"
                                    @click.prevent="
                                        showStatisticsRangeSelector()
                                    "
                                    >Range</b-button
                                >
                            </b-button-group>
                        </b-row>

                        <b-row class="mt-2">
                            <b-form-checkbox
                                id="stimSessionsLoad"
                                name="stimSessionsLoad"
                                value="true"
                                unchecked-value="false"
                                @change="loadStimulationSessions"
                            >
                                Load Stimulation Sessions
                            </b-form-checkbox>
                        </b-row>
                    </b-col>
                </b-card>
            </b-col>

            <b-col cols="8" v-if="statsDateRangeDisplayed">
                <b-card>
                    <b-row>
                        <b-col cols="8">
                            <b-form-datepicker
                                v-model="statsStartDate"
                                size="sm"
                            ></b-form-datepicker>
                            To
                            <b-form-datepicker
                                v-model="statsEndDate"
                                size="sm"
                            ></b-form-datepicker>
                        </b-col>
                        <b-col>
                            <b-button
                                size="sm"
                                variant="outline-primary"
                                @click="showStatisticsInRange"
                            >
                                <b-icon-search font-scale="1.3"></b-icon-search
                                >Get Use Data
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-5">
            <b-col>
                <b-card
                    :header="capitalizeFirstLetter(statsLabel) + ' statistics'"
                >
                    <div
                        v-if="usageStatistics.activeStimulationSecondsCount > 0"
                    >
                        Patients <br />
                        <h2>{{ usageStatistics.patientsCount }}</h2>

                        Therapists <br />
                        <h2>{{ usageStatistics.therapistsCount }}</h2>

                        Therapy sessions (of which guest)<br />
                        <h2>
                            {{ usageStatistics.therapySessionsCount }} ({{
                                usageStatistics.guestTherapySessionsCount
                            }})
                        </h2>

                        Exercises (of which guest)
                        <h2>
                            {{ usageStatistics.exercisesCount }} ({{
                                usageStatistics.guestExercisesCount
                            }})
                        </h2>
                    </div>

                    <div v-else>No tracked stimulation sessions.</div>
                </b-card>
            </b-col>

            <b-col>
                <b-card header="Sessions Statistics">
                    <div
                        v-if="usageStatistics.activeStimulationSecondsCount > 0"
                    >
                        Sessions per patient
                        <h2>
                            {{
                                this.roundNumber(
                                    (usageStatistics.therapySessionsCount -
                                        usageStatistics.guestTherapySessionsCount) /
                                        usageStatistics.patientsCount
                                )
                            }}
                        </h2>

                        Exercises per session
                        <h2>
                            {{
                                this.roundNumber(
                                    usageStatistics.exercisesCount /
                                        usageStatistics.therapySessionsCount
                                )
                            }}
                        </h2>

                        Repetitions per sessions
                        <h2>
                            {{
                                this.roundNumber(
                                    usageStatistics.repetitionsCount /
                                        usageStatistics.therapySessionsCount
                                )
                            }}
                        </h2>

                        Repetitions per exercise
                        <h2>
                            {{
                                this.roundNumber(
                                    usageStatistics.repetitionsCount /
                                        usageStatistics.exercisesCount
                                )
                            }}
                        </h2>
                    </div>

                    <div v-else>No tracked stimulation sessions.</div>
                </b-card>
            </b-col>

            <b-col>
                <b-card header="Other">
                    <div
                        v-if="usageStatistics.activeStimulationSecondsCount > 0"
                    >
                        Total repetitions
                        <h2>{{ usageStatistics.repetitionsCount }}</h2>

                        Total stimulation time <br />
                        <h2>
                            {{
                                getActiveStimulationTimeString(
                                    usageStatistics.activeStimulationSecondsCount
                                )
                            }}
                        </h2>

                        Stimulation time per session
                        <h2>
                            {{
                                getActiveStimulationTimeString(
                                    usageStatistics.activeStimulationSecondsCount /
                                        usageStatistics.therapySessionsCount
                                )
                            }}
                        </h2>

                        Stimulation time per exercise
                        <h2>
                            {{
                                getActiveStimulationTimeString(
                                    usageStatistics.activeStimulationSecondsCount /
                                        usageStatistics.exercisesCount
                                )
                            }}
                        </h2>
                    </div>

                    <div v-else>No tracked stimulation sessions.</div>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col>
                <b-card header="Most used exercises by repetition">
                    <div
                        class="topExerciseChart"
                        v-if="this.drawMostUsedExercises"
                    >
                        <DoughnutChart
                            ref="most_used_exercises"
                            :chartData="mostUsedExerciseChartData"
                            :options="mostUsedExerciseChartOptions"
                        >
                        </DoughnutChart>
                    </div>

                    <div v-else>
                        No stimulation sessions were recorded for this
                        {{ statsLabel }}.
                    </div>
                </b-card>
            </b-col>
        </b-row>

        <b-row
            class="mt-3"
            v-if="this.drawMostUsedExercises && this.stimSessionsLoad"
        >
            <b-col>
                <b-card header="Stimulation Sessions">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Exercise</th>
                                <th>Start date</th>
                                <th>End date</th>
                                <th>Repetitions</th>
                                <th>Stimulation [s]</th>
                                <th>Patient ID</th>
                                <th>Therapist ID</th>
                                <th>License ID</th>
                                <th v-if="isIntentoSales">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="this.stimSessionsAreLoading">
                                <td>Loading sessions. Please be patient, this might take a while...</td>
                            </tr>
                            <tr
                                v-for="stimSession in stimSessions"
                                :key="stimSession.id"
                            >
                                <td>
                                    {{
                                        formatExerciseName(
                                            stimSession.exerciseName
                                        )
                                    }}
                                </td>
                                <td>
                                    {{
                                        toLocaleDateTime(
                                            stimSession.startDatetime
                                        )
                                    }}
                                </td>
                                <td>
                                    {{
                                        toLocaleDateTime(
                                            stimSession.endDatetime
                                        )
                                    }}
                                </td>
                                <td>{{ stimSession.repetitions }}</td>
                                <td>{{ stimSession.activeSeconds }}</td>
                                <td>{{ stimSession.patientId }}</td>
                                <td>{{ stimSession.therapistId }}</td>
                                <td>{{ stimSession.licenseId }}</td>
                                <td class="text-right" v-if="isIntentoSales">
                                    <b-row>
                                        <b-button
                                            variant="primary"
                                            size="sm"
                                            class="m-1"
                                            @click.prevent="
                                                $router.push({
                                                    name: 'stimulationSession',
                                                    params: {
                                                        stimulationSessionId:
                                                            stimSession.id,
                                                        licenseId:
                                                            stimSession.licenseId,
                                                    },
                                                })
                                            "
                                        >
                                            <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
                                        </b-button>
                                    </b-row>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-card>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from "@/services/api.service";
import DoughnutChart from "@/components/DoughnutChart";
import randomColor from "randomcolor";
import {
    formatExerciseName,
    formatSeconds,
    formatDateTimeToLocaleString,
} from "@/components/utils.js";

const mostUsedExerciseChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
        animateRotate: false,
    },
};

export default {
    name: "ExerciseStatistics",
    components: { DoughnutChart },
    props: {
        licenseId: {
            type: [String, Number],
            required: false,
        },
        customerId: {
            type: [String, Number],
            required: true,
        },
        siteId: {
            type: [String, Number],
            required: true,
        },
    },
    computed: {
        ...mapGetters('auth', ['isIntentoSales'])
    },
    data() {
        return {
            drawMostUsedExercises: false,
            usageStatistics: {
                patientsCount: 0,
                therapistsCount: 0,
                therapySessionsCount: 0,
                guestTherapySessionsCount: 0,
                exercisesCount: 0,
                guestExercisesCount: 0,
                repetitionsCount: 0,
                activeStimulationSecondsCount: 0,
            },
            mostUsedExerciseChartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: [],
                        data: [],
                    },
                ],
            },
            mostUsedExerciseChartOptions: mostUsedExerciseChartOptions,
            statsDateRangeDisplayed: false,
            statsStartDate: "2020-01-01",
            statsEndDate: "2020-12-31",
            statsAllSelected: false,
            statsThisMonthSelected : false,
            statsLastMonthSelected: true,
            statsThisWeekSelected: false,
            statsDateRangeSelected: false,
            statsLabel: "",
            statsPeriodLastMonth : null,
            statsPeriodThisMonth: null,
            statsPeriodWeek: null,
            statsPeriodAll: null,
            stimSessions: [],
            stimSessionsLoad: false,
            stimSessionsAreLoading : false
        };
    },
    mounted() {
        this.initStatistics();
        this.showThisMonthStatistics();
    },
    methods: {
        initStatistics() {
            this.mostUsedExerciseChartData.labels = [];
            this.mostUsedExerciseChartData.datasets[0].data = [];
            this.mostUsedExerciseChartData.datasets[0].backgroundColor = [];

            if (this.licenseId != null) {
                this.statsLabel = "license";
            } else {
                this.statsLabel = "site";
            }

            var today = new Date();

            // Monthly start/end date
            this.statsPeriodThisMonth = {
                start: new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    1
                ).toISOString(),
                end: today.toISOString(),
            };

            var lastMonthFirstDay = new Date(today);
            lastMonthFirstDay.setMonth(lastMonthFirstDay.getMonth() - 1);
            lastMonthFirstDay.setDate(1);

            // day = 0 => One day less than first day of the month which is last day of the previous month.
            var lastMonthEndDay = new Date(today);
            lastMonthEndDay.setMonth(lastMonthEndDay.getMonth());
            lastMonthEndDay.setDate(0);

            this.statsPeriodLastMonth = {
                start: lastMonthFirstDay.toISOString(),
                end: lastMonthEndDay.toISOString(),
            };

            // Weekly start/end date
            // The following code calculates the starting day (Monday) of the current week.
            // It does so by getting the index of the current day in the week (monday = 1, ..., sunday = 7)
            // and substracting it from the current date of today, to get a date object for the monday of the
            // current week
            var monday = new Date();
            var day = today.getDay() || 7; // Get current day number, converting Sun. to 7
            if (day !== 1) {
                monday.setHours(-24 * (day - 1)); // Set the hours to day number minus 1
            }
            this.statsPeriodWeek = {
                start: new Date(
                    monday.getFullYear(),
                    monday.getMonth(),
                    monday.getDate()
                ).toISOString(),
                end: today.toISOString(),
            };

            // All-time start/end date
            this.statsPeriodAll = {
                start: "2020-01-01",
                end: today.toISOString(),
            };
        },
        resetStats() {
            this.statsAllSelected = false;
            this.statsThisMonthSelected = false;
            this.statsLastMonthSelected = false;
            this.statsThisWeekSelected = false;
            this.statsDateRangeDisplayed = false;
            this.statsDateRangeSelected = false;
        },
        showAllStatistics() {
            this.resetStats();
            this.statsAllSelected = true;

            this.getStatistics();
        },
        showThisMonthStatistics() {
            this.resetStats();
            this.statsThisMonthSelected = true;

            this.getStatistics();
        },
        showLastMonthStatistics() {
            this.resetStats();
            this.statsLastMonthSelected = true;

            this.getStatistics();
        },
        showWeeklyStatistics() {
            this.resetStats();
            this.statsThisWeekSelected = true;

            this.getStatistics();
        },
        showStatisticsRangeSelector() {
            this.resetStats();
            this.statsDateRangeDisplayed = true;
            this.statsDateRangeSelected = true;
        },
        showStatisticsInRange() {
            this.getStatistics();
        },
        getStartEndDate() {
            if (this.statsAllSelected) {
                return this.statsPeriodAll;
            } else if (this.statsThisMonthSelected) {
                return this.statsPeriodThisMonth;
            } else if (this.statsLastMonthSelected) {
                return this.statsPeriodLastMonth;
            } else if (this.statsThisWeekSelected) {
                return this.statsPeriodWeek;
            } else {
                return { start: this.statsStartDate, end: this.statsEndDate };
            }
        },
        async getStatistics() {
            var periodStartEndDates = this.getStartEndDate();
            if (this.licenseId != null) {
                this.usageStatistics = await ApiService.getLicenseUsageStatistics(
                    this.licenseId,
                    periodStartEndDates["start"],
                    periodStartEndDates["end"]
                );
            } else {
                this.usageStatistics = await ApiService.getSiteUsageStatistics(
                    this.siteId,
                    periodStartEndDates["start"],
                    periodStartEndDates["end"]
                );
            }

            this.mostUsedExerciseChartData.labels = [];
            this.mostUsedExerciseChartData.datasets[0].backgroundColor = [];
            this.mostUsedExerciseChartData.datasets[0].data = [];
            for (let exercise of this.usageStatistics.mostTenUsedExercises) {
                this.mostUsedExerciseChartData.labels.push(formatExerciseName(exercise.name));
                this.mostUsedExerciseChartData.datasets[0].backgroundColor.push(randomColor());
                this.mostUsedExerciseChartData.datasets[0].data.push(exercise.repetitions);
            }

            if (this.usageStatistics.mostTenUsedExercises.length > 0) {
                this.drawMostUsedExercises = true;
                if (this.$refs.most_used_exercises) {
                    this.$refs.most_used_exercises.update();
                }
            } else {
                this.drawMostUsedExercises = false;
            }

            this.getStimulationSessions();
        },
        loadStimulationSessions(checked) {
            if (checked == "true") {
                this.stimSessionsLoad = true;
            } else {
                this.stimSessionsLoad = false;
            }
            this.stimSessionsAreLoading = true;
            this.getStimulationSessions();
        },
        async getStimulationSessions() {
            var periodStartEndDates = this.getStartEndDate();

            this.stimSessions = [];

            if (this.stimSessionsLoad) {
                if (this.licenseId != null) {
                    this.stimSessions = await ApiService.getLicenseStimulationSessions(
                        this.licenseId,
                        periodStartEndDates["start"],
                        periodStartEndDates["end"]
                    );
                } else {
                    var licenses = await ApiService.getSiteLicenses(
                        this.siteId
                    );

                    this.stimSessions = [];
                    for (let license of licenses) {
                        var tempSessions = await ApiService.getLicenseStimulationSessions(
                            license.id,
                            periodStartEndDates["start"],
                            periodStartEndDates["end"]
                        );

                        // If the checkbox changes in the meanwhile, abort
                        if (!this.stimSessionsLoad) {
                            this.stimSessions = [];
                            return;
                        } else {
                            this.stimSessions = this.stimSessions.concat(tempSessions);
                        }
                    }
                }
            } else {
                this.stimSessions = [];
            }
            this.stimSessionsAreLoading = false;
        },
        roundNumber(number) {
            var result = number.toFixed(2);
            if (isNaN(result)) {
                return 'N/A';
            } else {
                return result;
            }
        },
        getActiveStimulationTimeString(seconds) {
            return formatSeconds(seconds);
        },
        capitalizeFirstLetter(input) {
            if (input.length > 0) {
                return input[0].toUpperCase() + input.slice(1);
            } else {
                return "";
            }
        },
        toLocaleDateTime(d) {
            return formatDateTimeToLocaleString(d);
        },
        formatExerciseName(s) {
            return formatExerciseName(s);
        },
    },
};
</script>

<style>
.btn-secondary[aria-pressed="true"] {
    background: #20c997 !important;
    color: white !important;
}
</style>
